<template>
  <div class="monad">
    <div class="public-margin-bottom">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addUnits"
        >新增单位</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      height="calc(100vh - 200px)"
      stripe
    >
      <el-table-column prop="UnitsNo" label="单位编号"> </el-table-column>
      <el-table-column prop="UnitsName" label="单位名称"> </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="onEditUnits(scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="onDeleteUnits(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <EditDialog
      :formData="formData"
      :etitle="etitle"
      :dialogTableVisible="showDialog"
      :callFatherFunction="callFatherFunction"
      @closeDialog="onCloseDialog"
    >
    </EditDialog>
  </div>
</template>

<script>
import setting from "@/api/setting.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  name: "StoreWebCategory",

  components: { EditDialog },

  directives: {},

  data() {
    return {
      tableData: [],
      formData: {},
      etitle: "",
      showDialog: false,
    };
  },

  mounted() {
    this.initPageData();
  },

  methods: {
    async initPageData() {
      try {
        let { data } = await setting.getParauntislist();
        this.tableData = data;
      } catch (e) {
        console.error(e);
      }
    },

    addUnits() {
      this.etitle = "添加单位";
      this.formData = {
        UnitsName: "",
      };
      this.showDialog = true;
    },

    onEditUnits(event) {
      this.etitle = "修改单位";
      this.formData = event;
      this.showDialog = true;
    },

    onDeleteUnits(event) {
      this.$confirm(`是否删除该‘${event.UnitsName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await setting.deleteParauntis({
              untis_guid: event.UnitsGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    onCloseDialog() {
      this.initPageData();
    },

    callFatherFunction() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.monad{
  padding-top: 20px;
}
</style>